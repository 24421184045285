import { Container, Paper } from "@mui/material";
import { navigate } from "gatsby";
import { useSnackbar } from "notistack";
import React from "react";
import { RoomTypeForm } from "../../components/forms/RoomTypeForm";
import { useSelectedPropertyContext } from "../../components/SelectedPropertyProvider";
import { translations } from "../../translations";
import {
  useDeleteRoomTypeByIdMutation,
  useGetRoomTypeSubscription,
  useUpdateRoomTypeMutation,
} from "../../__generated__/types";

const RoomTypeIdPage = ({ params }) => {
  const { selected_locale } = useSelectedPropertyContext();
  const { data, loading, error } = useGetRoomTypeSubscription({
    variables: { id: params.id },
  });
  const [updateRoomType] = useUpdateRoomTypeMutation();
  const [deleteRoomType] = useDeleteRoomTypeByIdMutation();
  const { enqueueSnackbar } = useSnackbar();

  if (loading) return null;
  if (error) return <div>{error.message}</div>;
  if (!data?.room_type_by_pk) return null;
  return (
    <Container sx={{ mt: 3, mb: 2 }}>
      <Paper sx={{ p: 2 }}>
        <RoomTypeForm
          initialValues={{
            room_type: data?.room_type_by_pk?.room_type,
            max_occupancy: data?.room_type_by_pk?.max_occupancy,
          }}
          onSubmit={async (values) => {
            updateRoomType({ variables: { id: params.id, _set: values } });
            enqueueSnackbar(
              `${translations[selected_locale].room_type} '${values.room_type}' ${translations[selected_locale].is_succesfully_updated}`,
              {
                variant: "success",
              }
            );
          }}
          onDelete={async () => {
            await deleteRoomType({ variables: { id: params.id } });
            enqueueSnackbar(
              `${translations[selected_locale].room_type} '${data?.room_type_by_pk?.room_type}' ${translations[selected_locale].is_succesfully_deleted}`,
              {
                variant: "success",
              }
            );
            navigate(-1);
          }}
        />
      </Paper>
    </Container>
  );
};

export default RoomTypeIdPage;
